import classes from "./LiveStream.module.css";
import Youtube from "../../../assets/live-stream/Youtube.png";
import SubmitButton from "../../../components/submit-button/SubmitButton";
import React from "react";
import {useHistory} from "react-router-dom";
import Logo from '../../../assets/live-stream/Logo.png'

const LiveStream = () => {

    const history = useHistory();

    const getEarlyAccess = async () => {
        history.push('/checkout')
    }

    return (
        <div className={classes.Container}>
            <div className={classes.ContainerInner}>
                <div className={classes.Content}>
                    <div className={classes.Header}>
                        <img src={Logo} className={classes.Logo}/>Pondeo live-streaming
                    </div>
                    <div className={classes.ImageMobile}>
                        <img src={Youtube} className={classes.Logo}/>
                    </div>
                    <div className={classes.Title}>There's nothing like live sports</div>
                    <div className={classes.Section}>
                        With Pondeo live-streaming, your friends, family and fans can all share in the excitement.
                    </div>
                    <div className={classes.Section}>
                        Simply add a contact to the app and they will receive a link to watch the game.
                    </div>
                    <div className={classes.ButtonContainer}>
                        <SubmitButton onClick={getEarlyAccess} className={classes.Button}>
                            Get early access
                        </SubmitButton>
                    </div>
                </div>
                <div className={classes.Image}>
                    <img src={Youtube} />
                </div>
            </div>
        </div>
    );
}

export default LiveStream;
