import CheckoutPreview from '../../assets/Packaging.png';
import classes from './Checkout.module.css';
import Form from "./form/Form";
import Stripe from '../../assets/Stripe.svg';
import Lock from '../../assets/Lock.svg';

const Checkout = () => {
    return (
        <div className={classes.Container}>
            <div className={classes.ContainerInner}>
                <div className={classes.Form}>
                    <Form />
                    <div className={classes.FormFooter}>
                        <img src={Lock} className={classes.Lock}/>
                        Safe and secure payments powered by
                        <img src={Stripe} className={classes.Stripe}/>
                    </div>
                </div>
                <div className={classes.Preview}>
                    <div className={classes.Title}>What's in the box</div>
                    <img src={CheckoutPreview}/>
                    <div className={classes.List}>
                        <div>
                            <div>
                                Pondeo smart gimbal
                            </div>
                            <div>
                                USB-C cord
                            </div>
                            <div>
                                User Manual
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Checkout;
