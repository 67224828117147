import classes from "./Setup.module.css";
import SubmitButton from "../../../components/submit-button/SubmitButton";
import React from "react";
import Gimbal from '../../../assets/setup/Gimbal.jpg';
import GimbalOnTripod from '../../../assets/setup/GimbalOnTripod.jpg';
import Youtube from '../../../assets/setup/Youtube.jpg';
import {useHistory} from "react-router-dom";


const Setup = () => {

    const history = useHistory();

    const getEarlyAccess = async () => {
        history.push('/checkout')
    }

    return (
        <div className={classes.Container}>
            <div className={classes.ContainerInner}>
                <div className={classes.Title}>Setup in seconds</div>
                <div className={classes.ImageSections}>
                    <div className={classes.Section}>
                        <img src={Gimbal} className={classes.Icon}/>
                    </div>
                    <div className={classes.Section}>
                        <img src={GimbalOnTripod} className={classes.Icon} />
                    </div>
                    <div className={classes.Section}>
                        <img src={Youtube} className={classes.Icon} />
                    </div>
                </div>
                <div className={classes.Sections}>
                    <div className={classes.Section}>
                        <img src={Gimbal} className={classes.IconMobile}/>
                        <div className={classes.SectionTitle}>
                            Attach it
                        </div>
                        <div className={classes.SectionSubtitle}>
                            Your smartphone attaches to Pondeo and connects with bluetooth.
                        </div>
                    </div>
                    <div className={classes.Section}>
                        <img src={GimbalOnTripod} className={classes.IconMobile} />
                        <div className={classes.SectionTitle}>
                            Mount it
                        </div>
                        <div className={classes.SectionSubtitle}>
                            Pondeo is compatible with any tripod. Once attached, you can start recording!
                        </div>
                    </div>
                    <div className={classes.Section}>
                        <img src={Youtube} className={classes.IconMobile} />
                        <div className={classes.SectionTitle}>
                            Watch it
                        </div>
                        <div className={classes.SectionSubtitle}>
                            The Pondeo app automatically uploads recorded footage to Youtube.
                        </div>
                    </div>
                </div>
                <div className={classes.ButtonContainer}>
                    <SubmitButton onClick={getEarlyAccess} className={classes.Button}>
                        Get early access
                    </SubmitButton>
                </div>
            </div>
        </div>
    )
}

export default Setup;
