import classes from "./Video.module.css";
import {useEffect, useRef} from "react";

const Video = ({src}) => {

    const ref = useRef();

    useEffect(() => {
        ref.current.play();
    }, []);

    return (
        <div className={classes.Container}>
            <div className={classes.GradientTop} />
            <div className={classes.GradientBottom} />
            <video className={classes.Video} src={src} autoPlay muted loop ref={ref} playsInline/>
        </div>

    )
}

export default Video;
