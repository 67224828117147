import Landing from "./landing/Landing";
import Tripods from "./tripods/Tripods";
import Mission from "./mission/Mission";
import WhyUs from "./why-us/WhyUs";
import Testimonial from "./testimonial/Testimonial";
import Setup from "./setup/Setup";
import BallTracking from "./ball-tracking/BallTracking";
import LiveStream from "./live-stream/LiveStream";


const Home = () => {

    return (
        <>
            <Landing />
            {/*<Tripods />*/}
            <WhyUs />
            <Setup />
            <BallTracking />
            {/*<Testimonial />*/}
            {/*<Mission />*/}
            <LiveStream />
        </>
    )
}

export default Home;
