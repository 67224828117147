import React from 'react';
import PlayPause from '../../../assets/icons/PlayPause.svg';
import Share from '../../../assets/icons/Share.svg';
import DollarSign from '../../../assets/icons/DollarSign.svg';
import classes from './WhyUs.module.css'
import SubmitButton from "../../../components/submit-button/SubmitButton";
import {isValidEmail} from "../../../util/authUtil";
import {useHistory} from "react-router-dom";

const WhyUs = () => {

    const history = useHistory();

    const getEarlyAccess = async () => {
        history.push('/checkout')
    }

    return (
        <div className={classes.Container}>
            <div className={classes.ContainerInner}>
                <div className={classes.Title}>Why Pondeo</div>
                <div className={classes.Subtitle}>
                    <span className={classes.Accent}>Save</span> money without sacrificing <span className={classes.Accent}>quality</span>
                </div>
                <div className={classes.Sections}>
                    <div className={classes.Section}>
                        <img src={PlayPause} className={classes.Icon}/>
                        <div className={classes.SectionTitle}>
                            Hands-free
                            recording
                        </div>
                        <div className={classes.SectionSubtitle}>
                            Attatch your phone to the device, screw it on any tripod,  sit back and enjoy the game!
                        </div>
                    </div>
                    <div className={classes.Section}>
                        <img src={Share} className={classes.Icon} />
                        <div className={classes.SectionTitle}>
                            Automatic
                            upload
                        </div>
                        <div className={classes.SectionSubtitle}>
                            The Pondeo app will livestream and upload every game directly onto YouTube.
                        </div>
                    </div>
                    <div className={classes.Section}>
                        <img src={DollarSign} className={classes.Icon} />
                        <div className={classes.SectionTitle}>
                            Price
                            savings
                        </div>
                        <div className={classes.SectionSubtitle}>
                            We are working every day to keep the cost of Pondeo under $100.
                        </div>
                    </div>
                </div>
                <div className={classes.ButtonContainer}>
                    <SubmitButton onClick={getEarlyAccess} className={classes.Button}>
                        Get early access
                    </SubmitButton>
                </div>
            </div>
        </div>
    )
}

export default WhyUs;
