import classes from "./Form.module.css";
import SubmitButton from "../../../components/submit-button/SubmitButton";
import {Link, useHistory} from "react-router-dom";
import {useState} from "react";
import CancelConfirmationPopup from "../cancel-confirmation-popup/CancelConfirmationPopup";
import Logo from "../../../assets/LogoFull.svg";

const Form = () => {

    const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);

    const history = useHistory();

    const onSubmit = () => {
        // window.open("https://buy.stripe.com/test_14k7tx78o6qw6E8288");
        window.open("https://buy.stripe.com/dR6bLs5u97snaeQfZ0");
    }

    const onCancel = () => {
        setIsCancelPopupOpen(true);
    }

    const onClosePopup = () => {
        history.push('/');
    }

    return (
        <div className={classes.Container}>
            <img src={Logo} className={classes.Logo}/>
            <div className={classes.Title}>
                Time to Complete Your Reservation!
            </div>
            <div className={classes.Subtitle}>
                Put a $5 reservation deposit down now and only pay a total of $74.99 at launch (50% of retail fee)
            </div>
            <SubmitButton className={classes.SubmitButton} onClick={onSubmit}>
                Yes Reserve 50% off
            </SubmitButton>
            <SubmitButton className={classes.CancelButton} onClick={onCancel}>
                I'm ok with losing 50%
            </SubmitButton>

            {isCancelPopupOpen && <CancelConfirmationPopup close={onClosePopup}/>}
        </div>
    )
}

export default Form;
