import classes from "./BallTracking.module.css";
import Gimbal from "../../../assets/ball-tracking/Gimbal.png";
import React from "react";
import SubmitButton from "../../../components/submit-button/SubmitButton";
import {useHistory} from "react-router-dom";

const BallTracking = () => {

    const history = useHistory();

    const getEarlyAccess = async () => {
        history.push('/checkout')
    }

    return (
        <div className={classes.Container}>
            <div className={classes.ContainerInner}>
                <div className={classes.Image}>
                    <img src={Gimbal} />
                </div>
                <div className={classes.Content}>
                    <div className={classes.Title}>Automatic ball tracking</div>
                    <div className={classes.Section}>
                        Pondeo uses a lightweight gimbal to automatically track the ball, allowing you to capture any
                        game you are recording.
                    </div>
                    <div className={classes.Section}>
                        The gimbal is powered by the Pondeo app, which uses advanced, patented AI to track the ball and ensure
                        that you capture every moment!
                    </div>
                    <div className={classes.ButtonContainer}>
                        <SubmitButton onClick={getEarlyAccess} className={classes.Button}>
                            Get early access
                        </SubmitButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BallTracking;
