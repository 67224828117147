import classes from "./EmailForm.module.css";
import TextInput from "../text-input/TextInput";
import SubmitButton from "../submit-button/SubmitButton";
import {useState} from "react";
import useHttpClient from "../../hooks/useHttpClient";
import {isValidEmail} from "../../util/authUtil";
import {useHistory} from "react-router-dom";
import {datadogRum} from '@datadog/browser-rum';
import {shouldReportUserSessions} from "../../env/env";

const Form = ({darkMode = false}) => {

    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(undefined);

    const {httpClient} = useHttpClient();
    const history = useHistory();

    const setDataDogUser = (email) => {
        if (!shouldReportUserSessions()) {
            return;
        }

        datadogRum.setUser({
            email: email,
        });
    }

    const subscribe = async () => {
        history.push('/checkout')

        // if (!isValidEmail(email)) {
        //     setError('Please enter a valid email address');
        //     return;
        // }
        //
        // setError(undefined);
        // setIsSubmitting(true);
        // setDataDogUser(email);
        // try {
        //     await httpClient.subscribeToEmailList(email);
        //     history.push('/checkout')
        // } catch (e) {
        //     console.log("Error: ", e);
        //     setError('An unexpected error occurred. Please try again.');
        // }
        // setIsSubmitting(false);
    }

    return (
        <div className={darkMode ? [classes.Container, classes.ContainerDark].join(' ') : classes.Container}>
            <div className={classes.Header}>
                <div className={classes.Title}>
                    Get your Pondeo at launch
                </div>
                <div>
                    Sign up for our super early bird deal and get notified at launch!
                </div>
            </div>
            <div className={classes.Content}>
                {/*<TextInput className={darkMode ? [classes.TextInput, classes.TextInputDark].join(' ') : classes.TextInput}*/}
                {/*           placeholder={"Enter your email address"}*/}
                {/*           value={email}*/}
                {/*           onChange={setEmail}*/}
                {/*           error={error}/>*/}
                <SubmitButton className={darkMode ? [classes.SubmitButton, classes.SubmitButtonDark].join(' ') : classes.SubmitButton}
                              isSubmitting={isSubmitting}
                              onClick={subscribe}>
                    Get early access
                </SubmitButton>
            </div>
        </div>
    )
}

export default Form;
