import classes from "./Contact.module.css";
import Logo from "../../assets/contact/Logo.png";
import {useHistory} from "react-router-dom";

const Contact = () => {
    const history = useHistory();

    return (
        <div className={classes.Container}>
            <div className={classes.ContainerInner}>
                <div className={classes.LogoContainer}>
                    <img src={Logo} className={classes.Logo} alt={'Pondeo - Smartphone powered AI sports camera'} onClick={() => history.push('/')}/>
                    <div className={classes.NavigationOptions}>
                        <div className={classes.NavigationOption} onClick={() => history.push('/about')}>About</div>
                        <div className={classes.NavigationOption} onClick={() => history.push('/contact')}>Contact</div>
                    </div>
                </div>
                <div className={classes.Content}>
                    <div className={classes.Title}>Contact</div>
                    <div className={classes.Section}>
                        Hello! Feel free to reach out to us via phone or email if you have any questions.
                        We will get back to you within 24 hours.
                    </div>
                    <div className={classes.SectionTitle}>
                        Phone
                    </div>
                    <div className={classes.Section}>
                        Call or text our customer support line to speak with a member of our team 720-233-1012
                    </div>
                    <div className={classes.SectionTitle}>
                        Email
                    </div>
                    <div className={classes.Section}>
                        Ryancrowleysoccer@gmail.com
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;
