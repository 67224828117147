import Mission from "../home/mission/Mission";
import classes from "../contact/Contact.module.css";
import Logo from "../../assets/contact/Logo.png";
import {useHistory} from "react-router-dom";

const About = () => {

    const history = useHistory();

    return (
        <div>
            <div className={classes.LogoContainer}>
                <img src={Logo} className={classes.Logo} alt={'Pondeo - Smartphone powered AI sports camera'} onClick={() => history.push('/')}/>
                <div className={classes.NavigationOptions}>
                    <div className={classes.NavigationOption} onClick={() => history.push('/about')}>About</div>
                    <div className={classes.NavigationOption} onClick={() => history.push('/contact')}>Contact</div>
                </div>
            </div>
            <Mission />
        </div>
    )
}

export default About;
