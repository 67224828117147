import Popup from "../../../components/popup/Popup";
import classes from "./CancelConfirmationPopup.module.css";
import SubmitButton from "../../../components/submit-button/SubmitButton";
import React from "react";

const CancelConfirmationPopup = ({close}) => {
    return (
        <Popup>
            <div className={classes.Container}>
                <div className={classes.Title}>
                    Your spot is reserved
                </div>
                <div className={classes.Content}>
                    We will be sending you a short survey to your
                    email regarding your experience. Taking 2 minutes to fill this out will greatly help us in developing the product.
                </div>
                <SubmitButton onClick={close}>
                    Got it
                </SubmitButton>
            </div>
        </Popup>
    );
}

export default CancelConfirmationPopup;
