import {Route, Switch} from "react-router-dom";
import classes from './App.module.css';
import Home from "./pages/home/Home";
import Checkout from "./pages/checkout/Checkout";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";

const AppInner = () => {

    return (
        <div className={classes.App}>
            <Switch>
                <Route path="/contact" component={Contact} />
                <Route path="/about" component={About} />
                <Route path="/checkout" component={Checkout} />
                <Route path="/" component={Home} />
            </Switch>
        </div>
    )
}

export default AppInner;
