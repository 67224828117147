import classes from './Mission.module.css';
import Gimbal from '../../../assets/Gimbal2Angled.png';
import EmailForm from "../../../components/email-form/EmailForm";

const Mission = () => {

    return (
        <div className={classes.Container}>
            <div className={classes.ContainerInner}>
                <div className={classes.Gimbal}>
                    <img src={Gimbal} alt={'Hands-free smartphone powered AI soccer camera'}/>
                </div>
                <div className={classes.Content}>
                    <div className={classes.Header}>
                        Our mission
                    </div>
                    <div className={classes.Title}>
                        We provide <span className={classes.Accent}>everyone</span> access to high quality,
                        hands-free game recording.
                    </div>

                    <div className={classes.Header}>
                        About us
                    </div>
                    <div className={classes.Section}>
                        Pondeo was founded by John Magnus and Ryan Crowley, two former teammates from the University of Washington Men’s soccer team.&nbsp;&nbsp;
                        We discovered a recent phenomena in youth sports that didn’t exist when we were playing... many people are now recording
                        and streaming their games with hands free, AI powered cameras!&nbsp;&nbsp; However, we were shocked to learn that the price of these
                        cameras can be thousands of dollars per year.
                    </div>
                    <div className={classes.Section}>
                        We asked ourselves the question, “why do people pay thousands of dollars a year for an AI camera when everyone
                        has a thousand dollar camera with AI capabilities already in their pocket -- their smart phone!”&nbsp;&nbsp; With Pondeo,
                        download the app, connect your phone to the gimbal, attach it to any tripod, and you are ready to record and
                        livestream any game with the same (or better) quality as any existing products on the market, but at a fraction
                        of the price.&nbsp;&nbsp; Follow along with our journey by entering your email below!
                    </div>
                    <div className={classes.Form}>
                        <EmailForm darkMode={true}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mission;
