import classes from './Landing.module.css';
import Logo from '../../../assets/LogoFull.svg';
import Form from "../../../components/email-form/EmailForm";
import VideoDesktop from '../../../assets/VideoDesktop.mp4';
import VideoMobile from '../../../assets/VideoMobile.mp4'
import Video from "./video/Video";
import {useHistory} from "react-router-dom";

const Landing = () => {

    const history = useHistory();

    return (
        <div className={classes.Container}>
            <div className={classes.VideoDesktop}>
                <Video src={VideoDesktop} />
            </div>

            <div className={classes.LogoContainer}>
                <img src={Logo} className={classes.Logo} alt={'Pondeo - Smartphone powered AI sports camera'} />
                <div className={classes.NavigationOptions}>
                    <div className={classes.NavigationOption} onClick={() => history.push('/about')}>About</div>
                    <div className={classes.NavigationOption} onClick={() => history.push('/contact')}>Contact</div>
                </div>
            </div>
            <div className={classes.VideoMobile}>
                <Video src={VideoMobile} />
            </div>
            <div className={classes.ContainerInner}>
                <div className={classes.Content}>
                    <div className={classes.Title}>
                        Smartphone enabled auto-tracking and game recording
                    </div>
                    <div className={classes.Subtitle}>
                        Connect your phone to Pondeo for hands-free, AI powered game recording and live streaming.
                    </div>
                    <div className={classes.DesktopForm}>
                        <Form />
                    </div>
                </div>
                <div className={classes.MobileForm}>
                    <Form />
                </div>
            </div>
        </div>
    )
}

export default Landing;
